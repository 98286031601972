import { getTodayFormat_yyyy_mm_dd, getTodayFormat_yyyy_mm, getYearAgoFormat_yyyy_mm } from "../util/util";

export const FORM_USER_INFORMATION = {
  yubinBangou: {
    value: "",
    maxLength: 7,
    requiredError: false,
    rangeError: false,
    notFoundAddressError: false,
    requiredSearchAddressError :false,
    isEdit: false
  },
  mansionName: {
    value: "",
    label: "",
    maxLength: 40,
    requiredError: false
  },
  roomNo: {
    value: "",
    maxLength: 25,
    requiredError: false
  },
  applicantFullName: {
    value: "",
    maxLength: 25,
    requiredError: false
  },
  applicantKana: {
    value: "",
    maxLength: 25,
    hankakuError:false,
    kanaError: false,
    requiredError: false
  },
  denwaBango: {
    value: "",
    maxLength: 15,
    minLength:10,
    requiredError: false,
    rangeErrorSubmit: false
  }
}

export const FORM_HONNIN_CONFIRMATION = {
  honninNameEqual: {
    value: "0",
    requiredError: false
  },
  honninName: {
    value: "",
    maxLength: 25,
    requiredError: false
  },
  relationship: {
    value: "",
    label: "",
    maxLength: 25,
    requiredError: false
  },
  yubinBangou: {
    value: "",
    maxLength: 7,
    requiredError: false,
    rangeError: false,
    notFoundAddressError: false,
  },
  toDouHuKen: {
    value: "",
    maxLength: 5,
    requiredError: false
  },
  siKuTyouSon: {
    value: "",
    maxLength: 25,
    requiredError: false
  },
  azaTyoume: {
    value: "",
    maxLength: 20,
    requiredError: false
  },
  tatemonoMei: {
    value: "",
    maxLength: 30
  },
  confirmSelectedOption: {
    value: "0",
    requiredError: false
  },
  kinyukanMei: {
    value: "",
    maxLength: 25,
    requiredError: false
  },
  shitenMei: {
    value: "",
    maxLength: 15,
    requiredError: false
  },
  denwaBango: {
    value: "",
    maxLength: 15,
    minLength:10,
    requiredError: false,
    rangeErrorSubmit: false
  },
  freeKaito: {
    value: "",
    maxLength: 300,
    requiredError: false,
  },
  systemError: false
}