import React, { useEffect } from "react";
import { FORM_HONNIN_CONFIRMATION } from "../config/form";
import { required, isShortRange, isHankakuNumberFormat } from "../util/validate";
import { call_api, updateValues } from "../util/util";
import { displayName, honninConfirmFullNameSelect, mousikomiSyaKubun, optionInputSelected } from "../config/config";
import { Layout } from "../Layout/Layout";
import { Submit } from "../components/Submit";
import { SystemError } from "../components/SystemError";
import { SectionWithText } from "../Layout/SectionWithText";
import { TermInputPair } from "../Layout/TermInputPair"
import { InputText } from "../parts/InputText";
import { InputRadio } from "../parts/InputRadio";
import { InputTel } from "../parts/InputTel";
import { FormValidationError } from "../components/FormValidationError";
import { CustomSelect } from "../parts/CustomSelect";
import { ZipCodeSearch } from "../components/ZipCodeSearch";

import liff from '@line/liff';

export function HonninConfirm(props) {
    document.title = displayName.TOKYU_TITLE;
    const [values, setValues] = React.useState(FORM_HONNIN_CONFIRMATION);
    const [paymFirst, ...paymRest] = honninConfirmFullNameSelect
    const [fetchInitialDataFinish, setFetchInitialDataFinish] = React.useState(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = React.useState(false);
    const [isExprided, setIsExprided] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("タイムアウトのため無効なリンクです。")
    const [selectedOption, setSelectedOption] = React.useState("0");
    const [isSucess, setIsSucess] = React.useState(false);
    const [isBot, setIsBot] = React.useState(false);
    const [guestId, setGuestId] = React.useState("");
    const [snsType, setSnSType] = React.useState("");

    const liffState = props.liffState
    const [room_id, setRoomId] = React.useState(props.roomId);
    const [patternId, setPatternId] = React.useState(props.patternId);

    let errCount = 0

    useEffect(() => {
        fetchInitialData(props.roomId);
    }, []);

    //初期表示処理
    async function fetchInitialData(_room_id) {
        if (liff.isInClient()) {
            liff.init({
                liffId: process.env.REACT_APP_LIFF_ID_HONNIN_CONFIRM
            }).catch((err) => {
                alert(err);
            })
        }

        var liffRoomId = _room_id
        var liffPatternId = patternId

        if (liffState) {
            let params = encodeURI(liffState)
            console.log(params);
            liffPatternId = params.slice(-1)

            liffRoomId = params.slice(8, 32)
            console.log(liffRoomId);

            if (!patternId) {
                setPatternId(liffPatternId)
                console.log(liffPatternId);
            }
        }

        var room_id = _room_id
        if (!_room_id) {
            setRoomId(liffRoomId)
            room_id = liffRoomId
        }

        let form = { "room_id": room_id, }
        let result = await call_api(process.env.REACT_APP_TOKYU_BASE_API_URL + "get-room-info", form)
        console.log(result)
        if (result.status_code === "200") {
            let isExprided = result.is_room_close
            setIsExprided(isExprided)
            setErrorMsg("タイムアウトのため無効なリンクです。")
            setIsBot(result.is_bot)
            setGuestId(result.world)
            setSnSType(result.roomInfo.tag62)

            let selectedOption = result.roomInfo.tag45
            if (selectedOption) {
                setSelectedOption(selectedOption)
                handleValue("confirmSelectedOption", selectedOption)
            }
            let honninNameEqual = result.roomInfo.tag42
            let relationship = (result.roomInfo.tag44) ? result.roomInfo.tag44 : ""
            if (honninNameEqual) {
                if (honninNameEqual === "お問合せ者と同じ") {
                    handleValue("honninNameEqual", "0")
                    relationshipSelectChange("relationship", "本人")

                } else {
                    handleValue("honninNameEqual", "1")
                    relationshipSelectChange("relationship", relationship)
                }
            } else {
                relationshipSelectChange("relationship", "本人")
            }
            handleValue("honninName", (result.roomInfo.tag43) ? result.roomInfo.tag43 : "")

            // handleValue("relationship", (result.roomInfo.tag44) ? result.roomInfo.tag44 : "")
            handleValue("denwaBango", (result.roomInfo.tag46) ? result.roomInfo.tag46 : "")
            let address = result.roomInfo.tag47
            if (address) {
                try {
                    let addressList = address.split(" ")
                    handleValue("yubinBangou", addressList[0])
                    handleValue("toDouHuKen", addressList[1])
                    handleValue("siKuTyouSon", addressList[2])
                    handleValue("azaTyoume", addressList[3])
                    handleValue("tatemonoMei", addressList[4])
                } catch (error) {
                    console.log(error)
                }
            }
            handleValue("kinyukanMei", (result.roomInfo.tag48) ? result.roomInfo.tag48 : "")
            handleValue("shitenMei", (result.roomInfo.tag49) ? result.roomInfo.tag49 : "")
            handleValue("freeKaito", (result.roomInfo.tag50) ? result.roomInfo.tag50 : "")

            setFetchInitialDataFinish(true)
        } else {
            setIsExprided(true)
            setErrorMsg("通信エラーが発生しました。\n画面を閉じて、エラーの旨をオペレータにお伝えください。")
        }
    }

    //郵便番号検索処理
    async function searchClick() {
        const yubinBangou = values.yubinBangou.value;
        const isRequiredError = required(yubinBangou);
        const isRangeError = isShortRange(yubinBangou, 7);
        const ishanKakuNumberError = isHankakuNumberFormat(yubinBangou);


        handleError("yubinBangou", "notFoundAddressError", false)
        handleError("yubinBangou", "requiredError", isRequiredError)
        handleError("yubinBangou", "rangeError", isRangeError)
        handleError("yubinBangou", "ishanKakuNumberError", ishanKakuNumberError)


        if (isRequiredError || isRangeError || ishanKakuNumberError) return;
        let response = await call_api(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${yubinBangou}`)
        var address = []
        if (response.status === 200) {
            handleError("yubinBangou", "serverAddressError", false)
            address = response.results;
        } else {
            handleError("yubinBangou", "serverAddressError", true)
            return
        }

        const notFound = (address == null || address.length === 0)
        handleError("yubinBangou", "notFoundAddressError", notFound)
        if (notFound) {
            handleValue("toDouHuKen", "")
            handleValue("siKuTyouSon", "")
        } else {
            let item = address[0]
            handleValue("toDouHuKen", item.address1)
            handleValue("siKuTyouSon", item.address2 + item.address3)
        }
    }

    function relationshipSelectChange(name, value) {
        if (name == "relationship" & value == "本人") {
            handleValue(name, value)
            handleLabel(name, value)
            return
        }

        let selectedTiem = mousikomiSyaKubun.find(item => item.value === value)
        handleValue(name, value)
        handleLabel(name, selectedTiem.label)
    }

    //送信処理
    async function submit() {
        //入力チェック
        errCount = 0
        if (patternId === "2") {
            handleError("freeKaito", "requiredError", required(values.freeKaito.value));
        } else {
            Object.keys(values).forEach(fieldName => {
                const field = values[fieldName];
                if (field.hasOwnProperty("requiredError") && fieldName !== "yubinBangou" && fieldName !== "freeKaito") {
                    let isRequired = required(field.value)
                    if ((fieldName == "honninName" && values.honninNameEqual.value == "0")) {
                        isRequired = false
                    }

                    //電話番号と書類送付先住所
                    if (selectedOption == "0") {
                        if ((fieldName == "kinyukanMei")) {
                            isRequired = false
                        }
                        if ((fieldName == "shitenMei")) {
                            isRequired = false
                        }
                    }

                    //電話番号と金融機関情報
                    if (selectedOption == "1") {
                        if ((fieldName == "yubinBangou")) {
                            isRequired = false
                        }
                        if ((fieldName == "toDouHuKen")) {
                            isRequired = false
                        }
                        if ((fieldName == "siKuTyouSon")) {
                            isRequired = false
                        }
                        if ((fieldName == "azaTyoume")) {
                            isRequired = false
                        }
                    }
                    //書類送付先住所と金融機関情報
                    if (selectedOption == "2") {
                        if ((fieldName == "denwaBango")) {
                            isRequired = false
                        }
                    }

                    handleError(fieldName, "requiredError", isRequired);
                }

                if (field.hasOwnProperty("rangeError") && fieldName !== "yubinBangou") {
                    handleError(fieldName, "rangeError", isShortRange(field.value, field.maxLength));
                }

                if (field.hasOwnProperty("rangeErrorSubmit") && selectedOption != "2") {
                    handleError(fieldName, "rangeErrorSubmit", isShortRange(field.value, field.minLength));
                }
            });
            // 相関チェック
            window.scrollTo({ top: 0, behavior: 'smooth', })
            handleError("yubinBangou", "requiredError", false)
            handleError("yubinBangou", "rangeError", false)
            handleError("yubinBangou", "notFoundAddressError", false)
            handleError("yubinBangou", "notKyusyuError", false)
        }
        //API連携
        if (errCount === 0) {
            let form =
            {
                "room_id": room_id,
                "patternId": patternId,
                "is_bot": isBot,
                "sns_type": snsType,
                "world": guestId,
                "formName": "FORM_HONNIN_CONFIRMATION",
            }

            if (patternId === "2") {
                form.freeKaito = values.freeKaito.value
            } else {
                form.honninNameEqual = values.honninNameEqual.value
                if (values.honninNameEqual.value === "0") {
                    form.honninName = ""
                } else {
                    form.honninName = values.honninName.value
                }
                form.relationship = values.relationship.value
                form.confirmSelectedOption = values.confirmSelectedOption.value

                //電話番号と書類送付先住所
                if (selectedOption == "0") {
                    form.denwaBango = (values.denwaBango.value) ? values.denwaBango.value : ""
                    form.address = `${values.yubinBangou.value} ${values.toDouHuKen.value} ${values.siKuTyouSon.value} ${values.azaTyoume.value.trim()} ${values.tatemonoMei.value.trim()}`
                    form.kinyukanMei = ""
                    form.shitenMei = ""
                }

                //電話番号と金融機関情報
                if (selectedOption == "1") {
                    form.denwaBango = (values.denwaBango.value) ? values.denwaBango.value : ""
                    form.address = ""
                    form.kinyukanMei = (values.kinyukanMei.value) ? values.kinyukanMei.value : ""
                    form.shitenMei = (values.shitenMei.value) ? values.shitenMei.value : ""
                }

                //書類送付先住所と金融機関情報
                if (selectedOption == "2") {
                    form.denwaBango = ""
                    form.address = `${values.yubinBangou.value} ${values.toDouHuKen.value} ${values.siKuTyouSon.value} ${values.azaTyoume.value.trim()} ${values.tatemonoMei.value.trim()}`
                    form.kinyukanMei = (values.kinyukanMei.value) ? values.kinyukanMei.value : ""
                    form.shitenMei = (values.shitenMei.value) ? values.shitenMei.value : ""
                }

                form.freeKaito = ""
            }

            console.log(form)
            let result = await call_api(process.env.REACT_APP_TOKYU_BASE_API_URL + "user-infor-register", form)
            console.log(result)

            if (result.statusCode == "200") {
                setValues({ ...values, "systemError": false })
                setIsSucess(true)
                liff.closeWindow()
                window.close()
            } else {
                console.log("else")
                setValues({ ...values, "systemError": true });
            }
        }
    }

    //以下ハンドラー
    function handleRadioChange(name, value) {
        handleValue(name, value)
        if (name == "confirmSelectedOption") {
            setSelectedOption(value)
        } else if (name = "honninNameEqual") {
            if (value == "0") {
                relationshipSelectChange("relationship", "本人")
                handleValue("honninName", "")
            } else {
                relationshipSelectChange("relationship", "")
            }
        }
    }
    //フリー入力
    function handleInputChange(e) {
        handleValue(e.target.name, e.target.value)
    }

    function handleValue(name, value) {
        updateValues({ [name]: { value } }, setValues);
    }
    function handleLabel(name, value) {
        updateValues({ [name]: { label: value } }, setValues);
    }
    function handleError(name, error, bool) {
        const updatedValues = { [name]: { [error]: bool } };
        if (bool) errCount += 1;
        updateValues(updatedValues, setValues);
    }

    return (
        <>
            {(fetchInitialDataFinish && !isSucess && !isExprided) && <Layout isLoading={false}>
                {patternId === "2" &&
                    <section>
                        <div className="formSet">
                            <TermInputPair label="フリー入力" required={true}>
                                <textarea
                                    onChange={handleInputChange}
                                    name="freeKaito"
                                    value={values.freeKaito.value}
                                    maxLength={values.freeKaito.maxLength}
                                    rows="3" >
                                </textarea>
                            </TermInputPair>
                            <FormValidationError formDataList={[values.freeKaito]} type={"textInput"}></FormValidationError>
                        </div>
                    </section>
                }
                {patternId === "1" &&
                    <SectionWithText label={"大切な個人情報になりますので、ご本人様確認をさせていただきます。改めて下記項目にご回答ください。"}>
                        <div className="formSet">
                            <TermInputPair label="お問合せいただいているのは、所有者（賃借人）ご本人ですか？" required={true}>
                                <div className="radio">
                                    <InputRadio name="honninNameEqual" radioInfo={paymRest} formData={values.honninNameEqual} handleValue={handleRadioChange}></InputRadio>
                                    <InputRadio name="honninNameEqual" radioInfo={[paymFirst]} formData={values.honninNameEqual} handleValue={handleRadioChange}></InputRadio>
                                </div>
                            </TermInputPair>
                            {(values.honninNameEqual.value == "1") &&
                                <div style={{ margin: "-25px 0px 0px 0px" }}>
                                    <TermInputPair label="所有者（賃借人）ご本人のフルネーム" required={true}>
                                        <InputText name="honninName" formData={values.honninName} handleValue={handleValue} disabled={(values.honninNameEqual.value === "" || values.honninNameEqual.value == "0")}></InputText>
                                    </TermInputPair>
                                    <FormValidationError formDataList={[values.honninName]}></FormValidationError>
                                </div>
                            }
                        </div>
                        {(values.honninNameEqual.value == "1") &&
                            <div>
                                <TermInputPair label="ご本人様からみた続柄" required={true}>
                                    <CustomSelect name="relationship" options={mousikomiSyaKubun} handleValue={relationshipSelectChange} formData={values.relationship} disabled={(values.honninNameEqual.value === "" || values.honninNameEqual.value == "0")} />
                                </TermInputPair>
                            </div>
                        }
                        <FormValidationError formDataList={[values.relationship]} type={"select"}></FormValidationError>
                        <TermInputPair label="下記のいずれかの項目を選択し、表示される項目を入力してください。" required={true}>
                            <div className="radio">
                                <InputRadio name="confirmSelectedOption" radioInfo={optionInputSelected} formData={values.confirmSelectedOption} handleValue={handleRadioChange}></InputRadio>
                            </div>
                        </TermInputPair>
                        {(selectedOption == "0" || selectedOption == "1") &&
                            <div className="optionInputSelected01">
                                <TermInputPair label="ご登録の電話番号" annotation="※ハイフン無しでご入力ください" required={true}>
                                    <InputTel name="denwaBango" formData={values.denwaBango} handleValue={handleValue}></InputTel>
                                </TermInputPair>
                                <FormValidationError formDataList={[values.denwaBango]} type={"textInput"}></FormValidationError>
                            </div>}
                        {(selectedOption == "0" || selectedOption == "2") &&
                            <div className="addressSearch">
                                <TermInputPair label="書類送付先住所" />
                                <TermInputPair label="郵便番号検索" annotation="※ハイフン無しでご入力ください" className="zip">
                                    <ZipCodeSearch name="yubinBangou" formData={values.yubinBangou} searchClick={searchClick} handleValue={handleValue}></ZipCodeSearch>
                                </TermInputPair>
                                <FormValidationError formDataList={[values.yubinBangou]} type={"yubinBangou"}></FormValidationError>
                                <TermInputPair label="都道府県名" required={true}>
                                    <InputText name="toDouHuKen" formData={values.toDouHuKen} handleValue={handleValue} />
                                </TermInputPair>
                                <FormValidationError formDataList={[values.toDouHuKen]} type={"addressInput"}></FormValidationError>
                                <TermInputPair label="市区町村" required={true}>
                                    <InputText name="siKuTyouSon" formData={values.siKuTyouSon} handleValue={handleValue} />
                                </TermInputPair>
                                <FormValidationError formDataList={[values.siKuTyouSon]} type={"addressInput"}></FormValidationError>
                                <TermInputPair label="丁・番地" required={true}>
                                    <InputText name="azaTyoume" formData={values.azaTyoume} handleValue={handleValue} />
                                </TermInputPair>
                                <FormValidationError formDataList={[values.azaTyoume]} type={"textInput"}></FormValidationError>
                                <TermInputPair label="建物名・部屋番号">
                                    <InputText name="tatemonoMei" formData={values.tatemonoMei} handleValue={handleValue}></InputText>
                                </TermInputPair>
                            </div>
                        }

                        {(selectedOption == "1" || selectedOption == "2") &&
                            <div className="paymentInformation">
                                <TermInputPair label="管理費や使用料等の引落口座" />
                                <TermInputPair label="金融機関名" required={true}>
                                    <InputText name="kinyukanMei" formData={values.kinyukanMei} handleValue={handleValue}></InputText>
                                </TermInputPair>
                                <FormValidationError formDataList={[values.kinyukanMei]} type={"textInput"}></FormValidationError>
                                <TermInputPair label="支店名" required={true}>
                                    <InputText name="shitenMei" formData={values.shitenMei} handleValue={handleValue}></InputText>
                                </TermInputPair>
                                <FormValidationError formDataList={[values.shitenMei]} type={"textInput"}></FormValidationError>
                            </div>}
                    </SectionWithText>
                }
                <Submit label="送信" onClick={submit} error={values.systemError} disabled={isSubmitDisabled}></Submit>
            </Layout>}
            {
                isExprided &&
                <Layout>
                    <SectionWithText>
                        <SystemError error_msg={errorMsg} />
                    </SectionWithText>
                </Layout>
            }
            {
                isSucess &&
                <Layout>
                    <SectionWithText>
                        <div id="success">
                            <p >{"この画面を閉じてチャットルームへお戻りください。"}</p>
                        </div>
                    </SectionWithText>
                </Layout>
            }
        </>
    );
}