import {required,isShortRange} from "../util/validate";
import { kyusyuKenList} from "../config/address";
//import {postObjList} from "../config/post";
import axios from 'axios'

export async function call_api(url,param){
  return await axios.post(url,param)
      .then((response) => {
          return response.data
      })
      .catch ((error) => {
        console.log(error)
        return {"status_code":"502"}
      }); 
}

//日付系
//-----------------------------------
export function getTodayFormat_yyyy_mm_dd(){
    let dt = new Date();
    let y = dt.getFullYear();
    let m = ("00" + (dt.getMonth()+1)).slice(-2);
    let d = ("00" + (dt.getDate())).slice(-2);

    return [y,m,d].join("-")
}

export function getTodayFormat_yyyy_mm(){
    let dt = new Date();
    let y = dt.getFullYear();
    let m = ("00" + (dt.getMonth()+1)).slice(-2);

    return [y,m].join("-")
}

export function getYearAgoFormat_yyyy_mm(){
    let dt = new Date();
    let month = dt.getMonth()+1;
    dt.setMonth(month -12)
    let y = dt.getFullYear();
    let m = ("00" + (dt.getMonth()+1)).slice(-2);

    return [y,m].join("-")
}

export function convertMinDate(date,minDate){
    return date < minDate ? minDate : date;
}

export function convertMaxDate(date,maxDate){
    return date > maxDate ? maxDate : date;
}


//ルーム情報抽出系
export function isOperater(mbaRoomInfo){
  let roomStatus = mbaRoomInfo.extra
  let isRoomFinish = ["finish", "close"].includes(roomStatus)
  let isOperator = mbaRoomInfo.tag9 === "0"


  return !isRoomFinish && isOperator
}
export function isBot(mbaRoomInfo){
  let roomStatus = mbaRoomInfo.extra
  let isRoomFinish = ["finish", "close"].includes(roomStatus)
  let isBot = mbaRoomInfo.tag9 === "1"


  return !isRoomFinish && isBot
}

//ハンドラー
export function updateValues(updatedFields,setValues) {
  setValues(prevValues => {
    const updatedValues = { ...prevValues };
    for (const fieldName in updatedFields) {
      if (Object.prototype.hasOwnProperty.call(updatedFields, fieldName)) {
        updatedValues[fieldName] = {
          ...updatedValues[fieldName],
          ...updatedFields[fieldName]
        };
      }
    }
    return updatedValues;
  });
}