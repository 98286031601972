export const A001_mousikomiSyaKubun = [
    { value: '01', label: '配偶者' },
    { value: '02', label: '家族' },
    { value: '99', label: 'その他' },
]

export const C007_mousikomiSyaKubun = [
    { value: '現契約者本人', label: '現契約者本人' },
    { value: '配偶者', label: '配偶者' },
    { value: '家族', label: '家族' },
    { value: 'その他', label: 'その他' }
]

export const optionInputSelected = [
    { label: "電話番号と書類送付先住所", value: "0", id: "opt01" },
    { label: "電話番号と金融機関情報", value: "1", id: "opt02" },
    { label: "書類送付先住所と金融機関情報", value: "2", id: "opt03" }
]

export const honninConfirmFullNameSelect = [
    { label: "いいえ", value: "1", id: "r01" },
    { label: "はい", value: "0", id: "r00" }
]

export const mousikomiSyaKubun = [
    { value: '', label: '- こちらからご選択ください -' },
    { value: '配偶者', label: '配偶者' },
    { value: '子', label: '子' },
    { value: '父母', label: '父母' },
    { value: '兄弟姉妹', label: '兄弟姉妹' },
    { value: '孫', label: '孫' },
    { value: '祖父母', label: '祖父母' },
    { value: '所有者の後見人', label: '所有者の後見人' },
    { value: '同居人', label: '同居人' },
    { value: '叔父叔母', label: '叔父叔母' },
    { value: '姪甥', label: '姪甥' },
    { value: '勤め先の会社', label: '勤め先の会社' },
    { value: 'その他', label: 'その他' }
]

export const mousikomiSyaDenwaBangouKubun = [
    { value: '携帯', label: '携帯' },
    { value: 'その他', label: 'その他' },
]

export const syomenHakkounoYouhi = [
    { value: '必要', label: '必要' },
    { value: '不要', label: '不要' },
]

export const saiHakkouRiyu = [
    { value: '紛失', label: '紛失' },
    { value: '未着', label: '未着' },
    { value: '期限切れ', label: '期限切れ' },
]

export const osiharaiHouhou = [
    { value: '口座振替', label: '口座振替' },
    { value: 'クレジットカード払い', label: 'クレジットカード払い' },
    { value: 'SMS決済', label: 'SMS決済' },
    { value: '振込票', label: '振込票' },
]


export const errorMessage = {
    required: "ご入力ください",
    requiredSelect: "選択してください",
    duplicateMonth: "対象年月1と異なる年月を選択してください",
    shortRange: "桁数が足りません",
    kanaFormat: "フリガナ（全角カナ）でご入力ください",
    requiredAddress: "郵便番号から検索のうえ、選択してください",
    requiredInputAddress: "郵便番号から検索、または入力してください",
    zenkakuFormat: "フリガナ（全角カナ）でご入力ください",
    phoneFormat: "携帯電話番号をご入力ください",
    requiredSearchAddress: "検索ボタンを押して、マンションを選択してください",
    requiredRedoSearchAddress: "もう一度検索ボタンを押して、マンションを選択してください",
    A001_CorrelationRequired: "1契約目からご入力ください",

    //郵便番号検索
    //-----------
    hankakuNumberFormat:"半角英数字で入力してください",
    requiredYubinBangou: "郵便番号を入力してください",
    shortRangeYubinBangou: "7桁でご入力ください",
    notKyusyu: "九州地方の郵便番号をご入力ください",
    notFoundAddress: "該当がありませんでした",
    serverAddressError: "郵便検索時に、通信エラーが発生しました。\n郵便番号が正しいかご確認の後、再度検索ボタンを押下してください。",

    //認証系
    //------------
    authFail: "認証に失敗しました",
    authExpired: "有効期限切れです。「認証コードを再送信する」から新しい認証コードを取得してください。"
}

export const displayName = {
    TOKYU_TITLE: "東急コミュニティー|お問合せ"
}