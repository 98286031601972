import React from "react";

export function TermInputPair({ children, className, required = false, label, annotation,addDecription, ddClassName = "" }) {
    return (
        <dl className={className}>
            <dt className={required ? "required" : ""}>{label}{annotation && <span>{annotation}</span>}</dt>
            {addDecription&& <p id="addDecription">{addDecription}</p>}
            <dd className={ddClassName}>{children}</dd>
        </dl>
    );
}