import React from "react";
import { SystemError } from "../components/SystemError";

export function Submit({label,onClick,error,disabled=false}) {
  let cssClass = disabled ? "disabledBtn" :"activeBtn";
  return (
      <section className="submit">
          <ul>
            <li>
              <button type="button" onClick={onClick} className={cssClass} disabled={disabled}><span className="btn_inner">{label}</span></button>
            </li>
          </ul>
          {error && <SystemError error_msg={"通信エラーが発生しました。\n時間をおいて送信ボタンを再度押下してください。それでも改善しない場合は、画面を閉じて再度入力から送信してください。"}/>}
        </section>
  );
}