import React from "react";

export class CollapseTextView extends React.Component {

    constructor() {
        super();
        this.state = {
            height: '8em',
            buttonText: "+ 続きを読む"
        };
    }

    countLines = () => {
        let height = this.testComp.offsetHeight;
        if ((height - 2) / 16 > 3.3) {
            this.setState({ showButton: true });
        }
    }

    showHidePara = () => {
        if (this.state.height == 'auto') {
            this.setState({
                height: '8em',
                buttonText: "+ 続きを読む"
            });
        } else {
            this.setState({ 
                height: 'auto' ,
                buttonText: "ー 閉じる"
            });
        }
    }

    componentDidMount() {
        this.countLines();
    }

    render() {
        return (
            < div>
                <div id="parent" style={{ height: this.state.height }}>

                    <div id="content" ref={(c) => this.testComp = c} style={{ height: 'auto' }}>
                        {this.props.children}
                    </div>
                </div>
                {this.state.showButton ?
                    <button id="showMoreButton" onClick={this.showHidePara}> {this.state.buttonText} </button>
                    : null
                }
            </div>
        );
    }

}