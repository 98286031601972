import './App.css';
import { UserInformationRegister } from "./pages/UserInformationRegister";
import { HonninConfirm } from './pages/HonninConfirm';
import { NoMatch } from "./pages/NoMatch";


import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

function getParam(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2]);
}

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" />
          <Route path="/userRegister" element={<UserInformationRegister roomId={getParam("roomid")} liffState={getParam("liff.state")} />} />
          <Route path="/honninConfirm" element={<HonninConfirm roomId={getParam("roomid")} patternId={getParam("patternId")} liffState={getParam("liff.state")} />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
