import React from "react";
import Select from 'react-select'

export function CustomSelect({name,options,formData,handleValue,disabled}) {
  const border = formData.requiredError || formData.rangeError || formData.phoneFormatError || formData.notKyusyuError || formData.notFoundAddressError || formData.rangeErrorSubmit ? "#f44336 solid 1px" : "#b5c6d2 solid 1px";
  const backgroundColor = formData.requiredError || formData.rangeError || formData.phoneFormatError || formData.notKyusyuError || formData.notFoundAddressError || formData.rangeErrorSubmit ? "#feeceb" : "";
  const customStyles = {
    control: () => (

      {
      // none of react-select's styles are passed to <Control />
　　　 //ここでボックス自体のスタイルをカスタマイズ
      width: "100%",
      background: "transparent",
      borderRadius: "4px",
      letterSpacing: "0.075em",
      position: "absolute"
    }),
  };

  const parentCustomStyles = {
    border: border,
    backgroundColor: backgroundColor,
  }

  function handleSelectChange(e) {
    handleValue(name,e.value)
  }

  return (
    <>
          <div className="custom" style={parentCustomStyles}>
            <Select 
              options={options}
              value={{label:formData.label,value:formData.value}}
              onChange={(e) => {handleSelectChange(e)}}
              styles={customStyles}
              noOptionsMessage={() => "該当する住所がありません"}
              className="select"
              classNamePrefix="rs"
              components={{
                IndicatorSeparator: () => null,
                IndicatorsContainer: () => null,
              }}
              isDisabled={disabled}
            />
          </div>    
    </>
  );
}